<template>
  <el-dialog :close-on-click-modal="false"
    v-model="dialogVisible"
    title=""
    width="50%"
  >
  <div style="margin-bottom:10px">
    <span>关键词：</span>
    <el-input v-model="listQuery.name" style="width:120px;margin-right:10px"></el-input>
    <span>商品编码：</span>
    <el-input v-model="listQuery.spbm" style="width:120px;margin-right:10px"></el-input>

    <span>类别：</span>
    <el-select v-model="listQuery.lb" placeholder="请选择" clearable style="width:80px;margin-right: 20px;">
      <el-option v-for="item in options" :key="item.leiBie" :label="item.leiBie" :value="item.leiBie"></el-option>
    </el-select>

    <el-button type="primary" size="small" @click="openThree">查询</el-button>
  </div>
    
    <el-table
      ref="multipleTableRef"
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      :height="contentStyleObj"
      border
    >
      <el-table-column label="类别" width="60">
        <template #default="scope">
          <span style="cursor: pointer;color: var(--themeColor,#17a2b8);" @click="openTwoLevel(scope.row.id)">{{ scope.row.leiBie }}</span>
        </template>
      </el-table-column>
      <el-table-column property="shuoMing" label="说明" />
    </el-table>
  </el-dialog>
  <twoLevel ref="twoLevel" @success="choiceProduct"></twoLevel>
  <threeLevel ref="threeLevel" @success="choiceProduct"></threeLevel>

</template>

<script>
import { productsList } from "@/api/crm"
import twoLevel from "./twoLevel.vue"
import threeLevel from './threeLevel'
export default {
  name:'oneLevel',
  components:{
    twoLevel,
    threeLevel
  },
  data(){
    return{
      dialogVisible:false,
      tableData:[],
      options:[],
      listQuery:{
        lb:''
      }
    }
  },
  created(){
    this.contentStyleObj= this.$getHeight(350)
  },
  methods:{
    init(list){
      this.tableData = list
      this.options = list
      this.dialogVisible = true
    },
    openTwoLevel(id){
      productsList({pid:id}).then(res => {
        if(res.data.data.list){
          this.$refs.twoLevel.init(res.data.data.list)
        }
      })
    },
    openThree(){
      productsList(this.listQuery).then(res => {
        if(res.data.data.list){
          this.$refs.threeLevel.init(res.data.data.list)
        }
      })
    },
    choiceProduct(e){
      this.$emit('success',e)
      this.dialogVisible = false
    }
  }
}
</script>

<style>

</style>