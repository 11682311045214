<template>
  <div class="main">
    <div class="top">
      <div class="top_left">
        位置：网上申请 &nbsp;&nbsp; <span style="color: #fe0000">注册申请</span>
      </div>
      <div class="top_right">北京时间:XXX</div>
    </div>
    <div class="main_content">
      <div class="title">
        <p>
          <a href="javascript:void(0)">【开始填写前，请点击这里查看商标注册网上申请填写要求】</a>
        </p>
      </div>
      <div class="tab_content">
        <div class="tab_size">
          <el-tabs v-model="activeName" type="card" class="demo-tabs" @tab-click="handleClick" :before-leave="beforeTabLeave">
            <el-tab-pane label="首页选项" name="syxx">
              <div class="tab_each">
                <div class="tab_list">
                  <el-form :model="temp" @submit.native.prevent :rules="rules" ref="ruleForm">

                    <el-row>
                      <el-col :span="8">
                        <div class="grid-content">申请人类型：</div>
                      </el-col>
                      <el-col :span="8">
                        <div class="">
                          <el-form-item prop="proposerType">
                            <el-select v-model="temp.proposerType" class="m-2" placeholder="- -请选择- -"
                              style="width: 80%">
                              <el-option v-for="item in proposer" :key="item.value" :label="item.label"
                                :value="item.value" />
                            </el-select>
                            <span style="color: #f00; padding-left: 3px">*</span>
                          </el-form-item>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint">请选择申请人类型</div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8">
                        <div class="grid-content">书式类型：</div>
                      </el-col>
                      <el-col :span="8">
                        <div class="">
                          <el-form-item prop="bookType">
                            <el-select v-model="temp.bookType" class="m-2" placeholder="- -请选择- -" style="width: 80%">
                              <el-option v-for="item in book" :key="item.value" :label="item.label"
                                :value="item.value" />
                            </el-select>
                            <span style="color: #f00; padding-left: 3px">*</span>
                          </el-form-item>

                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint">请选择书市类型</div>
                      </el-col>
                    </el-row>

                  </el-form>

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-button"><button @click="oneStep">下一步</button></div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="申请人信息" name="sqrxx">
              <div class="tab_each">
                <div class="tab_list">
                  <el-form :model="temp" @submit.native.prevent :rules="rules" ref="ruleForm">
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">代理文号：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item prop="dlwh">
                          <el-input v-model="temp.dlwh" style="width: 70%" />
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint"> 请填写代理文号,此为代理机构选填 </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">代理机构名称：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item prop="dljgmc">
                          <el-input v-model="temp.dljgmc" style="width: 70%" placeholder="青岛易创联合企业管理有限公司" readonly />
                          <span style="color: #f00; padding-left: 3px">*</span>
                        </el-form-item>
                      </el-col>

                      <el-col :span="8">
                        <div class="grid-hint"> 代理用户，本项不可变动 </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">代理人姓名：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item prop="dlrxm">
                          <el-input v-model="temp.dlrxm" style="width: 70%" />
                          <span style="color: #f00; padding-left: 3px">*</span>
                        </el-form-item>
                      </el-col>

                      <el-col :span="8">
                        <div class="grid-hint">请填写代理人姓名</div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">代理委托书：</div>
                      </el-col>
                      <el-col :span="11">
                        <div>
                          <el-form-item prop="dlwts">
                            <!-- <el-input v-model="temp.dlwts" style="width: 70%" /> -->
                            <upload @uploadimgzj="imgUrl" />
                            <span style="color: #f00; padding-left: 3px">*</span>
                          </el-form-item>
                        </div>
                      </el-col>

                      <el-col :span="8">
                        <div class="grid-hint">
                          请上传由申请人盖章或签字的中文代理委托书彩色扫描件，选择共同申请的请上传共同申请人/共有人盖章或签字的中文代理委托书彩色扫描件。格式为jpg，宽高的大小为4000px~600px,300dpi分辨率，大小不超过2M。若为多页，可按页上传
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">申请人名称：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item prop="sqrmc">
                          <el-input v-model="temp.sqrmc" style="width: 70%" />
                          <span style="color: #f00; padding-left: 3px">*</span>
                        </el-form-item>
                      </el-col>

                      <el-col :span="8">
                        <div class="grid-hint"> 请填写申请人名称,应与能够证明其身份的有效证件保持一致 </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">统一社会信用代码：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item prop="tax_no">
                          <el-input v-model="temp.tax_no" style="width: 70%" />
                          <span style="color: #f00; padding-left: 3px">*</span>
                        </el-form-item>
                      </el-col>

                      <el-col :span="8">
                        <div class="grid-hint"> 中国大陆申请人（法人或其它组织）请填写统一社会信用代码 </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">主体资格证明文件(中文)：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item prop="ztzgzmwj">
                          <uploadFile @uploadimgzj="ztzgzmwjUrl" />
                          <!-- <el-input style="width:60%;margin-right:10px" v-model="temp.ztzgzmwj"></el-input> -->
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint"> 请上传主体资格证明文件(中文)，格式为PDF，大小不超过2M。 </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">申请人地址：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item prop="sqrdz">
                          <el-input v-model="temp.sqrdz" style="width: 70%" />
                          <span style="color: #f00; padding-left: 3px">*</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint">
                          请填写申请人冠有省、市、县/区三级区划的详细地址,如四川省成都市青羊区玉沙路1000号;外国申请人应当同时填写包含国家/地区名称的详细、完整的中文地址，注明国别、市（县、州）、街道门牌号码
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">邮政编码：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item prop="yzbm">
                          <el-input v-model="temp.yzbm" style="width: 70%" />
                          <span style="color: #f00; padding-left: 3px">*</span>
                        </el-form-item>

                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint">请填写邮编</div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">申请人地址：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item prop="gnsqrlxdz">
                          <el-input v-model="temp.gnsqrlxdz" style="width: 70%" />
                          <span style="color: #f00; padding-left: 3px">*</span>
                        </el-form-item>

                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint">
                          国内申请人填写此栏，用于接收该商标后继商标业务的法律文件，不用于本件申请的送达。本申请为电子申请，本件申请所产生的商标文件，按《商标网上服务系统用户使用协议》的规定送达。
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">邮政编码：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item prop="gnsqryzbm">
                          <el-input v-model="temp.gnsqryzbm" style="width: 70%" />
                          <span style="color: #f00; padding-left: 3px">*</span>
                        </el-form-item>

                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint">填写国内申请人联系地址对应邮政编码。ztzgzmwjUrl</div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">国内申请人电子邮箱：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item prop="dzyx">
                          <el-input v-model="temp.dzyx" style="width: 70%" />
                          <span style="color: #f00; padding-left: 3px">*</span>
                        </el-form-item>

                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint">
                          国内申请人填写此栏，留存申请人联系方式，不用于本件申请的送达提示。本件申请所产生的商标文件，按《商标网上服务系统用户使用协议》的规定送达。
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">联系人：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item prop="lxr">
                          <el-input v-model="temp.lxr" style="width: 70%" />
                          <span style="color: #f00; padding-left: 3px">*</span>
                        </el-form-item>

                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint">请填写联系人名称</div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">联系电话：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item prop="lxrdh">
                          <el-input v-model="temp.lxrdh" style="width: 70%" />
                          <span style="color: #f00; padding-left: 3px">*</span>
                        </el-form-item>

                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint">请填写申请人联系电话</div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">传真（含地区号）：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item>
                          <el-input v-model="temp.cz" style="width: 70%" />
                        </el-form-item>

                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint">
                          请填写联系人传真，选填
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <div class="grid-button">
                          <button @click="lastStep">上一步</button><button style="margin-left: 40px"
                            @click="oneStep">下一步</button>
                        </div>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="商标声明" name="sbsm">
              <div class="tab_each">
                <div class="tab_list">
                  <el-form :model="temp" @submit.native.prevent :rules="rules" ref="ruleForm">
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">商标类型：</div>
                      </el-col>
                      <el-col :span="11">
                        <div>
                          <el-form-item>
                            <el-radio-group v-model="temp.sblx">
                              <el-radio label="一般">一般</el-radio>
                              <el-radio label="集体">集体</el-radio>
                              <el-radio label="证明">证明</el-radio>
                            </el-radio-group>
                          </el-form-item>
                        </div>
                        <div>
                          <a href="javascript:void(0)" style="font-size: 16px">【更多说明】</a>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint">请选择商标类型</div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">是否三维标志：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item>
                          <el-radio-group v-model="temp.sfswbz">
                            <el-radio label="否">否</el-radio>
                            <el-radio label="是">是</el-radio>
                          </el-radio-group>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint">
                          请选择是否三维标志，地理标志商标不可选
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">是否颜色组合：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-radio-group v-model="temp.sfyszh">
                          <el-radio label="否">否</el-radio>
                          <el-radio label="是">是</el-radio>
                        </el-radio-group>
                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint">
                          请选择是否颜色组合，地理标志商标不可选
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">声音商标：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-radio-group v-model="temp.sysb">
                          <el-radio label="否">否</el-radio>
                          <el-radio label="是">是</el-radio>
                        </el-radio-group>
                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint">
                          勾选是否声音商标，地理标志商标不可选
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">商标名称：</div>
                      </el-col>
                      <el-col :span="11">
                        <el-form-item prop="sbmc">
                          <el-input v-model="temp.sbmc" style="width: 70%" />
                          <span style="color: #f00; padding-left: 3px">*</span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint">
                          商标为纯图形的，此栏填写“图形”。商标含有汉字、英文字母和数字的，此栏填写文字主要部分（汉字为对应的简化汉字）；商标为少数民族文字的，此栏填写“图形”；商标为其他文字的，此栏填写“图形”。商标审查以实际上传的商标图样为准。
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5">
                        <div class="grid-content">商标说明：</div>
                      </el-col>
                      <el-col :span="11">
                        <div>
                          <el-form-item prop="sbsm">
                            <el-input v-model="temp.sbsm" style="width: 70%" :rows="7" type="textarea" />
                            <span style="color: #f00; padding-left: 3px">*</span>
                          </el-form-item>
                        </div>
                        <div>
                          <a href="javascript:void(0)" style="font-size: 16px">【更多说明】</a>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="grid-hint">
                          请填写商标说明，如实填写，填写商标说明时只能使用简体中文、英文或阿拉伯数字，不得使用其他任何文字或字符。
                        </div>
                      </el-col>
                    </el-row>
                  </el-form>

                  <el-row>
                    <el-col :span="24">
                      <div class="grid-button">
                        <button @click="lastStep">上一步</button><button style="margin-left: 40px"
                          @click="oneStep">下一步</button>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="共同申请信息" name="gtsqxx">
              <div class="tab_each">
                <div class="tab_list">
                  <el-row>
                    <el-col :span="5">
                      <div class="grid-content">是否共同申请：</div>
                    </el-col>
                    <el-col :span="11">
                      <el-radio-group v-model="temp.sfgtsq">
                        <el-radio label="是">是</el-radio>
                        <el-radio label="否">否</el-radio>
                      </el-radio-group>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-hint">
                        请选择是否有共同商标申请人
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-button">
                        <button @click="lastStep">上一步</button><button style="margin-left: 40px"
                          @click="oneStep">下一步</button>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="优先权信息" name="yxqxx">
              <div class="tab_each">
                <div class="tab_list">
                  <el-row>
                    <el-col :span="5">
                      <div class="grid-content">优先权声明：</div>
                    </el-col>
                    <el-col :span="11">
                      <div>
                        <el-radio-group v-model="temp.yxqsm">
                          <el-radio label="无">无</el-radio>
                          <el-radio label="在先优先权">在先优先权</el-radio>
                          <el-radio label="展会优先权">展会优先权</el-radio>
                        </el-radio-group>
                      </div>
                      <div>
                        <a href="javascript:void(0)" style="font-size: 16px">【更多说明】</a>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-hint">请选择优先权声明</div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-button">
                        <button @click="lastStep">上一步</button><button style="margin-left: 40px"
                          @click="oneStep">下一步</button>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="商品" name="sp">
              <div class="tab_each">
                <div class="tab_list">
                  <el-row>
                    <el-col :span="5">
                      <div class="grid-content">类别：</div>
                    </el-col>
                    <el-col :span="11">
                      <div>
                        <el-input v-model="leiBieShow" style="width: 70%" readonly />
                      </div>
                      <div>
                        <a href="javascript:void(0)" style="font-size: 16px">【更多说明】</a>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-hint">请选择商品</div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <span @click="addCreate">点击添加商品/服务项目</span>
                    <el-table ref="multipleTableRef" :data="tableData" @selection-change="handleSelectionChange" border>
                      <el-table-column type="index" width="55" label="序号" />
                      <!-- <el-table-column type="selection" width="55" /> -->
                      <el-table-column label="类别" width="120">
                        <template #default="scope">{{ scope.row.leibie }}</template>
                      </el-table-column>
                      <el-table-column property="leisiqun" label="类似群" width="120" />
                      <el-table-column property="leiBie" label="商品编码" width="120" />
                      <el-table-column property="shuoMing" label="商品名称" />
                      <el-table-column label="操作" width="120">
                        <template #default="scope">
                          <el-button @click="deleteRow(scope.$index, tableData)">删除</el-button>
                        </template>
                      </el-table-column>
                    </el-table>

                    <oneLevel ref="oneLevel" @success="choiceProduct"></oneLevel>

                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-button">
                        <button @click="lastStep">上一步</button><button style="margin-left: 40px"
                          @click="oneStep">下一步</button>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="商标图样" name="sbty">
              <div class="tab_each">
                <div class="tab_list">
                  <el-row>
                    <el-col :span="5">
                      <div class="grid-content">代理委托书：</div>
                    </el-col>
                    <el-col :span="11">
                      <div>
                        <upload @uploadimgzj="imgUrl" />
                        <span style="color: #f00; padding-left: 3px">*</span>
                      </div>
                    </el-col>

                    <el-col :span="8">
                      <div class="grid-hint">
                        请按照下面的图样说明选择图样
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="5">
                      <div class="grid-content">以肖像作为商标申请注册：</div>
                    </el-col>

                    <el-col :span="11">
                      <el-form-item>
                        <el-radio-group v-model="temp.yxqsm">
                          <el-radio label="是">是</el-radio>
                          <el-radio label="否">否</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>

                    <!-- <el-col :span="8"
                      ><div class="grid-hint">
                        代理用户，本项不可变动
                      </div></el-col
                    > -->
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <div class="grid-button">
                        <button @click="lastStep">上一步</button><button style="margin-left: 40px"
                          @click="oneStep">确认</button>
                      </div>
                    </el-col>
                  </el-row>

                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, getCurrentInstance, reactive } from "vue";
import { productsList, saveTrademark } from "@/api/crm"
import upload from "@/components/uploadImg/upload";
import uploadFile from "./compoments/uploadFile.vue";

import oneLevel from "./compoments/oneLevel.vue";
export default {
  name: "trademark",
  components: {
    oneLevel,
    upload,
    uploadFile
  },
  data() {
    return {
      activeName: 'syxx',
      temp: {
        proposerType: '',
        bookType: '',
        dlwh: "",
        dljgmc: "青岛易创联合企业管理有限公司",
        dlrxm: "",
        dlwts: '',
        sqrmc: '',
        tax_no: '',
        ztzgzmwj: 'xxx',
        sqrdz: '',
        yzbm: '',
        gnsqrlxdz: '',
        gnsqryzbm: '',
        dzyx: '',
        lxr: '',
        lxrdh: '',
        sblx: '',
        sfswbz: '',
        sfyszh: '',
        sysb: '',
        sbmc: '',
        sbsm: ''
      },
      proposer: [
        {
          label: "法人或其他组织",
          value: "法人或其他组织",
        },
        {
          label: "自然人",
          value: "自然人",
        },
      ],
      book: [
        {
          label: "中国大陆",
          value: "中国大陆",
        },
        {
          label: "国外",
          value: "国外",
        },
        {
          label: "中国台湾",
          value: "中国台湾",
        },
        {
          label: "中国香港",
          value: "中国香港",
        },
        {
          label: "中国澳门",
          value: "中国澳门",
        },
      ],
      tableData: [],
      rules:{
        proposerType: [
          { required: true, message: '请选择申请人类型', trigger: 'change' },
        ],
        bookType:[
          { required: true, message: '请选择书式类型', trigger: 'change' },
        ],
      },
      leiBieShow:'',
      leibie1:[],
      leibiequn:[]
    }
  },
  created() {
  },
  methods: {
    addCreate() {
      productsList({}).then(res => {
        if (res.data.data.list) {
          this.$refs.oneLevel.init(res.data.data.list)
        }
      })
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
      this.tableData = rows
      this.leibie1 = []
      this.leibiequn = []
      this.changeProduct()
    },
    choiceProduct(e) {
      e.map(v => {
        // 类别
        v.leibie = v.value.split('%hpb%').splice(0, 1).join('')
        // 类似群
        v.leisiqun = v.value.split('%hpb%').splice(1, 1).join('')
      })
      if(this.tableData.length == 0){
        this.tableData = e
      }else{
        e.map(v => {
          this.tableData.push(v)
        })
      }
      this.changeProduct()
    },
    // 修改类别、传的id值
    changeProduct(){
      this.tableData.map(v => {
        this.leibie1.push(v.id)
        this.leibiequn.push(v.leibie)
      })
      this.temp.spid = Array.from(new Set(this.leibie1)).join(',')
      let leiBieShow1 = Array.from(new Set(this.leibiequn))
      this.leiBieShow = leiBieShow1.join(',')
    },

    beforeTabLeave(){
      if(this.temp.proposerType == '' || this.temp.bookType == '' ){
        this.$qzfMessage("请将首页选项填写完整","2")
        return false
      }
    },
    // 上一步
    lastStep() {
      if (this.activeName == 'sqrxx') {
        this.activeName = 'syxx'
      } else if (this.activeName == 'sbsm') {
        this.activeName = 'sqrxx'
      } else if (this.activeName == 'gtsqxx') {
        this.activeName = 'sbsm'
      } else if (this.activeName == 'yxqxx') {
        this.activeName = 'gtsqxx'
      } else if (this.activeName == 'sp') {
        this.activeName = 'yxqxx'
      } else if (this.activeName == 'sbty') {
        this.activeName = 'sp'
      }
    },
    // 下一步
    oneStep() {
      if(this.temp.proposerType == '' ){
        this.$qzfMessage("请选择申请人类型","2")
        return
      }
      if(this.temp.bookType == '' ){
        this.$qzfMessage("请选择书式类型","2")
        return
      }
      if (this.activeName == 'syxx') {
        this.activeName = 'sqrxx'
      } else if (this.activeName == 'sqrxx') {
        this.activeName = 'sbsm'
      } else if (this.activeName == 'sbsm') {
        this.activeName = 'gtsqxx'
      } else if (this.activeName == 'gtsqxx') {
        this.activeName = 'yxqxx'
      } else if (this.activeName == 'yxqxx') {
        this.activeName = 'sp'
      } else if (this.activeName == 'sp') {
        this.activeName = 'sbty'
      } else if (this.activeName == 'sbty') {
        return
        saveTrademark(this.temp).then(res => {
          //console.log(res);
        })
      }
      //console.log(this.temp);
    },
    imgUrl(val){
      this.temp.dlwts = val;
    },
    ztzgzmwjUrl(val){
      this.temp.ztzgzmwj = val
    }
  }

};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  outline: none;
}

.main {
  width: 99%;
  margin: 0 auto;
}

.top {
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid #c5ccdc;
  border-width: 0 0 1px 1px;
  color: rgb(102, 102, 102);
}

.top_left {
  padding-left: 38px;
}

.main_content {
  padding: 28px 0 0 37px;
  min-height: 900px;
  max-height: 4000px;
  background-color: #f6f8f9;
}

.title {
  height: 50px;
  position: relative;
}

.title p {
  width: 100%;
  position: absolute;
  left: -36px;
  border-bottom: 1px solid #c5ccdc;
  padding-left: 32px;
}

.title p a {
  color: #02a6ee;
}

.tab_content {
  padding: 10px 0 0 10px;
}

.tab_size {
  width: 95%;
}

:deep(.el-tabs__item) {
  padding: 0 46px;
  background-color: #ddffff !important;
  font-size: 17px;
  color: rgb(102, 102, 102);
  border: none;
  width: 14.3%;
}

:deep(.el-tabs__nav) {
  border: none !important;
  width: 100%;

}

:deep(.el-tabs__header) {
  margin: 0 !important;
}

.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
  background-color: #3399ff !important;
  border-right: 1px solid gray;
  border-top: 1px solid gray;
  border-left: 1px solid gray;
  color: #fff;
}

.el-tabs--card>.el-tabs__header .el-tabs__item {
  width: 14.3%;
}

.tab_each {
  border: 1px solid #d7d7d7;
  border-top: none;
  height: 2000px;
}

.tab_list {
  width: 99%;
  margin: 0 auto;
  padding-top: 19px;
}

.el-row {
  padding: 7px 0;
  border-bottom: 1px dashed #cdcbca;
  align-items: center;
}

.grid-content {
  text-align: right;
  padding-right: 30px;
  font-size: 16px;
  line-height: 40px;
  color: rgb(102, 102, 102);
}

.grid-hint {
  color: rgb(102, 102, 102);
  font-size: 14px;
}

.grid-button {
  text-align: center;
}

.grid-button button {
  background-image: linear-gradient(#00bafa, #0391dd);
  width: 124px;
  height: 42px;
  border: none;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  color: #fff;
  border-radius: 4px;
}

.upload {
  width: 70px;
  height: 30px;
  color: rgb(102, 102, 102);
  font-size: 14px;
  padding: 0;
}

:deep(.el-radio__label) {
  font-size: 16px;
}

:deep(.el-checkbox__label) {
  font-size: 16px;
}

.el-form-item {
  margin-bottom: 0;
}
</style>